<template>
  <div>
    <select-holder
      :loading="loading"
      :placeholder="$t('components.multi_items_select.select.placeholder')"
      :shown-items="shownItems"
      @show-modal="handleModalShow"
    />
    <th-modal
      name="devices-select"
      height="800px"
      width="1200px"
      :title="$t('components.multi_items_select.devices.select.title')"
    >
      <group-items-select
        v-model="selectedDevices"
        fuzzy-search
        resource="devices"
        :resource-list="resources.devices"
        :normalise-keys="normaliseKeys"
      />

      <template #footer>
        <actions @cancel="handleCancel" @save="handleSave" />
      </template>
    </th-modal>
  </div>
</template>

<script>
import GroupItemsSelect from '@/components/group-items-select'
import SelectHolder from './components/select-holder'
import Actions from './components/actions'

export default {
  components: {
    GroupItemsSelect,
    SelectHolder,
    Actions
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedDevices: [],
      loading: false
    }
  },
  computed: {
    shownItems() {
      return this.resources.devices?.filter((device) =>
        this.modelValue.includes(device.id)
      )
    },
    secondaryTitle() {
      return {
        bound: this.$t('pages.devices.edit.form.bound.label'),
        cfd: this.$t('pages.devices.edit.form.cfd.label'),
        eda: this.$t('pages.devices.edit.form.eda.label'),
        free: this.$t('pages.devices.edit.form.free.label'),
        pending: this.$t('pages.devices.edit.form.pending.label'),
        printer: this.$t('pages.devices.edit.form.printer.label')
      }
    }
  },
  methods: {
    normaliseKeys(item) {
      return {
        ...item,
        title_main: `${this.secondaryTitle[item.type]} - ${item.name}`
      }
    },
    handleCancel() {
      this.$thModal.hide('devices-select')
    },
    handleSave() {
      this.$emit('update:modelValue', this.selectedDevices)
      this.$thModal.hide('devices-select')
    },
    handleModalShow() {
      this.selectedDevices = [...this.modelValue]
      this.$thModal.show('devices-select')
    }
  }
}
</script>
